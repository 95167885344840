<template>
    <div class="w-full">
		<SiteHeader />
		<section class="w-full py-16 bg-siteRed-300">
			<div class="container mx-auto">
				<h2 class="mb-4 text-6xl leading-10 text-center text-white lg:text-7xl font-cormorant">Benefits</h2>
				<h3 class="text-2xl font-thin text-center text-white font-roboto">of Pico Laser Treatment</h3>
			</div>
			<div class="container pt-8 mx-auto">
				<div class="grid items-start grid-cols-2 gap-10 md:grid-cols-2 lg:grid-cols-5">
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-01.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-2/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto"><span class="block text-lg font-thin lg:text-xl">Achieve an</span>Even Skin Tone</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-02.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto"><span class="block text-lg font-thin lg:text-xl">Reduce</span>Scarring & Pigmentation Easily</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-03.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto"><span class="block text-lg font-thin lg:text-xl">Create</span>Brighter & Tighter Skin</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-04.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto"><span class="block text-lg font-thin lg:text-xl">Safe &</span>US-FDA Approved</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-05.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto"><span class="block text-lg font-thin lg:text-xl">Quick</span>Procedure</h2>
						</div>
					</div>
					
				</div>
			</div>
		</section>
		<section class="w-full py-16">
			<div class="container mx-auto">
				<div class="w-full mx-auto mb-10 text-center">
					<h2 class="mb-2 text-5xl leading-none text-center text-siteRed-100 lg:text-7xl font-cormorant">Target Troubled Areas</h2>
					<span class="text-2xl text-siteRed-100">With Effective Pigmentation Treatment</span>
				</div>
				<div class="grid items-start grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<img src="/images/vavavoom-img-01.jpg" class="mb-6 rounded-xl" />
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Pigmentation</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<img src="/images/vavavoom-img-02.jpg" class="mb-6 rounded-xl" />
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Tattoo<br>Removal</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<img src="/images/vavavoom-img-03.jpg" class="mb-6 rounded-xl" />
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Acne<br>Scars</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<img src="/images/vavavoom-img-04.jpg" class="mb-6 rounded-xl" />
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Fine Lines<br>& Wrinkles</h2>
						</div>
					</div>
				</div>
				<div class="my-12 border-b border-siteRed-200"></div>
				<div class="flex flex-wrap items-center justify-between">
					<div class="w-full mb-10 lg:w-3/5 lg:mb-0">
						<h3 class="text-2xl font-roboto mb-6">With a keen eye for aesthetics, Dr Vanessa Phua can help you achieve a renewed, confident look with Pico Lasers.</h3>
						<h3 class="font-roboto text-base font-medium">(Pico Laser is a medical-use only device that should only be performed by an MOH-accredited medical professional like Dr Vanessa Phua.)</h3>
					</div>
					<div class="items-end justify-end w-full lg:w-2/5">
						<div class="items-end float-left lg:float-right"><a href="#enquire" class="flex flex-wrap items-center px-8 py-4 text-xl text-white rounded-full lg:text-2xl lg:px-20 lg:float-left bg-siteRed-default hover:underline">Enquire Now <img src="/images/arrow-icon-white.png" class="h-6 ml-4" /></a></div>
					</div>
				</div>
			</div>
		</section>
		<section class="relative flex flex-wrap w-full lg:pb-0 bg-siteRed-100">
			<div class="container flex flex-wrap mx-auto">
				<div class="w-full py-12 lg:w-3/5 lg:py-24 lg:pr-8 xl:py-32">
					<div class="mb-10">
						<h2 class="mb-2 text-5xl leading-none text-white lg:text-7xl font-cormorant">Pico Laser Treatment</h2>
						<span class="text-2xl text-white">With Dr Vanessa Phua</span>
					</div>
					<p class="text-white">Pico laser technology uses a type of laser that functions in picoseconds – a trillion of a second!</p>
					<p class="text-white">With laser firing so rapidly in a highly precise and targeted way, pigment particles are rapidly broken down without affecting surrounding skin, and without the skin needing to be heated up for long.</p>
					<p class="text-white">Backed by rigorous testing and R&D, pico laser technology has shown to be effective in improving many cosmetic skin issues, such as hyperpigmentation, tattoo removal, acne scarring and wrinkles.</p>
					<p class="text-white">This technology has been adapted for use in various non-invasive laser treatments, with the <b>PicoSure Pro</b> being a trusted option with a strong track record.</p>
					<p class="text-white">As an experienced aesthetic doctor, Dr Vanessa Phua is pleased to provide the <b>PicoSure Pro</b> to suitable patients, or <b>other treatments</b> if it is assessed to be a better option for their condition and preferences.</p>
				</div>
				<div class="right-0 z-10 hidden w-full h-full bg-center bg-no-repeat bg-cover lg:block lg:absolute lg:w-2/5 xl:bg-top" style="background-image: url('/images/dr-vanessa-phua.jpg')">
				</div>
			</div>
			<img src="/images/dr-vanessa-phua.jpg" class="w-full h-full lg:hidden" />
		</section>
		<section class="relative flex flex-wrap w-full pb-12 lg:pb-0">
			<div class="absolute left-0 z-10 hidden w-full h-full bg-center bg-no-repeat bg-cover lg:block lg:w-2/5 xl:bg-top" style="background-image: url('/images/dr-vanessa-phua-photo.jpg')"></div>
			<div class="container flex flex-wrap mx-auto">
				<div class="w-full py-12 ml-auto lg:w-3/5 lg:py-24 lg:pl-16 xl:py-32">
					<div class="mb-10">
						<span class="mb-4 text-2xl text-siteRed-100">Meet Our Doctor</span>
						<h2 class="text-5xl leading-none text-siteRed-100 lg:text-7xl font-cormorant">Dr Vanessa Phua</h2>
					</div>
					<ul class="pl-4 mb-6 leading-relaxed list-disc">
						<li class="pb-2 pl-2">BMed Science, BMBS (Nottingham, UK)</li>
						<li class="pb-2 pl-2">MRCS (Edinburgh), MMED (S'pore) Ophthalmology</li>
						<li class="pb-2 pl-2">Dip Practical Dermatology (Cardiff, Wales, UK)</li>
						<li class="pb-2 pl-2">Filler Rejuvenation For Body & Face Contouring Certified</li>
						<li class="pb-2 pl-2">Thermage certified Physician</li>
						<li class="pb-2 pl-2">Ulthera (Focused Ultrasound Skin Lifting) Certified Physician & Trainer</li>
					</ul>
					<p>Dr Vanessa Phua brings with her many years of experience and is a trainer for several forms of facial rejuvenation such as Ultherapy and fillers.</p>
					<p>Dr Phua sees the pursuit of beauty as a seamless combination of art and science.</p>
					<p>She has a history of training in Ophthalmology and Aesthetic medicine internationally. Educated in the United Kingdom with an Ophthalmology surgical background, she has a keen interest for aesthetic rejuvenation of the individual.</p>
				</div>
			</div>
			<img src="/images/dr-vanessa-phua-photo.jpg" class="w-full h-full lg:hidden" />
		</section>
		<section class="w-full bg-opacity-0 bg-grad lg:bg-opacity-100">
			<div class="container mx-auto">
				<div class="flex flex-wrap">
					<div class="w-full px-6 pb-16 lg:w-3/5 bg-siteRed-400 lg:pr-36 lg:pr-0">
						<h2 class="mb-12 text-5xl font-normal leading-none lg:text-6xl 2xl:text-7xl font-cormorant"><span class="text-siteRed-300">Pico laser treatments</span> are<br>non<span class="font-roboto">-</span>invasive with <span class="text-siteRed-300">minimal pain and downtime</span> for a stress<span class="font-roboto">-</span>free session.</h2>
						<a href="#enquire" class="px-8 py-4 text-xl text-white rounded-full lg:text-2xl lg:px-20 lg:float-left bg-siteRed-300 hover:underline">Enquire Now</a>
					</div>
					<div class="w-full px-6 py-16 lg:w-2/5 bg-siteRed-100 lg:pl-0 lg:pl-20">
						<h3 class="mb-16 text-2xl leading-normal text-white lg:text-4xl">A typical pico laser treatment session will consist of the following:</h3>
						<div class="flex flex-wrap">
							<div class="w-full mb-10 md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-06.png" class="w-full"></div>
									<div class="w-4/5">
										<span class="text-xl font-normal leading-none text-white">
											Quick<br>Procedure
										</span>
									</div>
								</div>
							</div>
							<div class="w-full mb-10 md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-07.png" class="w-full"></div>
									<div class="w-4/5">
										<span class="text-xl font-normal leading-none text-white">
											Minimal<br>Discomfort
										</span>
									</div>
								</div>
							</div>
							<div class="w-full mb-10 md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-08.png" class="w-full"></div>
									<div class="w-4/5">
										<span class="text-xl font-normal leading-none text-white">
											Minimal<br>Downtime
										</span>
									</div>
								</div>
							</div>
							<div class="w-full mb-10 md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-09.png" class="w-full"></div>
									<div class="w-4/5">
										<span class="text-xl font-normal leading-none text-white">
											Long-lasting<br>Results
										</span>
									</div>
								</div>
							</div>
							<div class="w-full mb-10 md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-10.png" class="w-full"></div>
									<div class="w-4/5">
										<span class="text-xl font-normal leading-none text-white">
											Reduced<br>Pigmentation
										</span>
									</div>
								</div>
							</div>
							<div class="w-full md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-11.png" class="w-full"></div>
									<div class="w-4/5">
										<span class="text-xl font-normal leading-none text-white">
											Improved Skin<br>Tone and Texture
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="w-full py-16 bg-siteRed-200">
			<div class="container mx-auto">
				<h2 class="mb-10 text-6xl text-center text-black lg:text-7xl font-cormorant">FAQ</h2>
				<div>
         			<div class="mb-2">
            			<div @click="index = 0" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">Is there any downtime after a pico laser treatment session?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 0 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
            			</div>
						<collapse-transition>
						<div v-show="index === 0" class="p-8">
							<p class="mb-4 text-black">Pico laser treatments have little to no downtime, with many patients returning to work and continue with their daily activities right after their session.</p>
						</div>
						</collapse-transition>
          			</div>
					<div class="mb-2">
            			<div @click="index = 1" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">Where can pico laser treatments be used on?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 1 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
            			</div>
						<collapse-transition>
						<div v-show="index === 1" class="p-8">
							<p class="mb-4 text-black">Pico laser treatments can be used on any part of the body with uneven pigmentation or scarring. Popular locations are exposed areas such as the face, arms and legs. Some patients opt for pico laser treatments on areas such as the back or abdomen for tattoo removal.</p>
						</div>
						</collapse-transition>
          			</div>
					<div class="mb-2">
            			<div @click="index = 2" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">Are there any risks?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 2 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
            			</div>
						<collapse-transition>
						<div v-show="index === 2" class="p-8">
							<p class="mb-4 text-black">Unlike other lasers, pico laser technology does not use heat energy to burn away skin for forced healing or to remove pigment, thereby removing the risk of burning, significant redness, scarring and surrounding skin damage.</p>
						</div>
						</collapse-transition>
          			</div>
					  <div class="mb-2">
            			<div @click="index = 3" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">How does PicoSure Pro work?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 3 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
            			</div>
						<collapse-transition>
						<div v-show="index === 3" class="p-8">
							<p class="mb-4 text-black">As one of several pico laser treatments that patients can choose from, and one of the newer ones available, PicoSure Pro delivers 755nm energy in mere picoseconds – so quick that the skin does not undergo high thermal damage. Patients can benefit from the precise removal of unwanted pigmentation, fine lines and scars; as well as a boost in the production of collagen and elastin. The treatment is customizable, versatile, gentle and suitable for all skin types.</p>
						</div>
						</collapse-transition>
          			</div>
        		</div>
			</div>
		</section>
		<section class="w-full py-16">
			<div class="container mx-auto text-center"><a name="enquire"></a>
				<div class="w-full mx-auto lg:w-3/4">
					<h2 class="mb-10 text-5xl leading-none text-center text-siteRed-100 lg:text-7xl font-cormorant">Achieve pigmentation<span class="font-roboto">-</span>free skin and a radiant glow today.</h2>
					<span class="text-2xl text-siteRed-100">Fill up the form to find out more about our pico laser treatments.</span>
				</div>
				<div class="w-full mx-auto mt-10 lg:w-3/4">
					<iframe allowTransparency="true" style="min-height:450px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5743128.html"></iframe>
				</div>
			</div>
		</section>
		<section class="flex flex-wrap">
			<div class="w-full bg-center bg-no-repeat bg-cover lg:w-1/2" style="background-image: url('/images/clinic-photo.jpg')">
				<img src="/images/clinic-photo.jpg" class="block w-full lg:hidden" />
			</div>
			<div class="flex justify-start w-full lg:w-1/2 bg-siteRed-100">
				<div class="py-12 container-half lg:pl-16 lg:py-24">
					<h2 class="mb-10 text-6xl leading-none text-white lg:text-8xl font-cormorant">Our Clinic</h2>
					<p class="text-white">Established in 1999, The Chelsea Clinic is dedicated to the pursuit of aesthetic excellence and places a strong emphasis on the holistic approach to a patient’s well-being focuses through personalized health care.</p>
					<p class="text-white">Our mission is to provide safe, effective and affordable treatments to our clients without compromising on the quality of our work.</p>
				</div>
			</div>
    	</section>
        <SiteFooter />
    </div>
</template>

<script>
import {CollapseTransition} from "vue2-transitions";
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'Home',
	components: {
		SiteHeader,
		SiteFooter,
		CollapseTransition
	},
	data(){
		return {
			index: 0,
		}
    },
}
</script>
<style>

@screen lg{
	.bg-grad{
		background: rgb(247,247,247);
		background: -moz-linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		background: -webkit-linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		background: linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7f7f7",endColorstr="#8a2224",GradientType=1); 
	}
}
</style>